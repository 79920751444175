import React, { useState } from "react"
import { AnimatedButton } from "@components/shared"
import { IFormState } from "./types"
import { WPApi } from "@api"

import {
  wrapper__form,
  wrapper__form__input,
  wrapper__form__area,
  wrapper__form__btn,
} from "./styles/contact.module.scss"

const defaultState: IFormState = {
  name: "",
  message: "",
  email: "",
  imBusy: false,
  msg: "",
}

const Form: React.FC = (props): JSX.Element => {
  const { language } = props
  const [state, setState] = useState(defaultState)

  const sendMessage = () => {
    setState({ ...state, imBusy: true })
    WPApi.sendMail(state.email, state.name, state.message)
      .then((data) => {
        setState({
          name: "",
          message: "",
          email: "",
          imBusy: false,
          msg: data.message,
        })
      })
      .catch((data) => {
        console.log(data)
      })
  }

  const handleChange = (ev) => {
    const { value } = ev.target
    setState({ ...state, [ev.target.name]: value })
  }

  return (
    <form className={wrapper__form}>
      <input
        type="text"
        name="name"
        onChange={(ev) => handleChange(ev)}
        value={state.name}
        className={wrapper__form__input}
        placeholder={translation[language].name}
      />
      <input
        type="email"
        name="email"
        value={state.email}
        onChange={(ev) => handleChange(ev)}
        className={wrapper__form__input}
        placeholder={translation[language].email}
      />
      <textarea
        name="message"
        onChange={(ev) => handleChange(ev)}
        className={wrapper__form__area}
        value={state.message}
        placeholder={translation[language].msg}
        rows={10}
      />
      <AnimatedButton
        onClick={() => sendMessage()}
        className={wrapper__form__btn}
      >
        {translation[language].button}
      </AnimatedButton>
    </form>
  )
}

export default Form

const translation = {
  pl: {
    name: "Imię",
    email: "email",
    msg: "Twoja wiadomość...",
    button: "Wyślij wiadomość",
  },
  en: {
    name: "Name",
    email: "email",
    msg: "Your message...",
    button: "Send message",
  },
}
