import { AnimatedButton } from "@components/shared"
import React, { useState } from "react"
import {
  wrapper__content__question,
  wrapper__content__question__inner,
  wrapper__content__question__title,
  wrapper__content__question__title__text,
  wrapper__content__question__title__open,
  wrapper__content__question__title__close,
} from "./styles/contact.module.scss"
import Right from "../../components.refactored/standard/Home/icons/Animated/right.svg"
interface Props {
  element: {
    question: string
    text: string
    listTitle?: string
    listElements?: string[]
  }
}

const Question: React.FC<Props> = ({ element }) => {
  const [open, setOpen] = useState(false)
  return (
    <div
      className={wrapper__content__question}
      onClick={() => setOpen((prev) => !prev)}
    >
      <div className={wrapper__content__question__title}>
        <div className={wrapper__content__question__title__text}>
          {element.question}
        </div>
        <AnimatedButton
          className={
            open
              ? wrapper__content__question__title__open
              : wrapper__content__question__title__close
          }
        >
          <img src={Right} alt="" />
        </AnimatedButton>
      </div>

      {open && (
        <div className={wrapper__content__question__inner}>
          <p>{element.text}</p>
          {element.listTitle && (
            <>
              <p>{element.listTitle}</p>
              <ul>
                {element.listElements?.map((e, i) => (
                  <li key={i}>{"- " + e}</li>
                ))}
              </ul>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default Question
