import React from "react"
import { useActions, LangCtx, useContextState } from "@components/contexted"
import { Layout } from "@components/layout"
import {
  wrapper,
  wrapper__divider,
  circle,
  wrapper__content,
} from "./styles/contact.module.scss"
import Form from "./Form"
import { AnimatedSection } from "@components/shared"
import Leafes from "../../components.refactored/standard/Home/icons/leaves.svg"
import Question from "./Question"

const Contact = ({ pageContext }) => {
  const { page } = pageContext
  const { language } = useContextState(LangCtx, ["language"])
  const translationUrls = {
    pl: `/pl/contact/`,
    en: `/en/contact/`,
  }
  let title = "Kontakt - The Old Hemp"
  if (language === "en") {
    title = "Contact - The Old Hemp"
  }
  let seo = {
    ...page[0].seo,
    title: title,
  }
  return (
    <Layout {...page[0]} seo={seo} translationUrl={translationUrls}>
      <div className={wrapper}>
        <h3>{translation[language].title}</h3>
        <h3>{translation[language].second_title}</h3>
        <img className={wrapper__divider} src={Leafes} />
        <div>
          <Form language={language} />
          <div className={circle} />
        </div>
        <p>{translation[language].p} </p>
        <a
          href="mailto:contact@theoldhemp.com"
          style={{ textDecoration: "underline" }}
        >
          contact@theoldhemp.com
        </a>
        <p>{translation[language].p2}</p>
        <p>{translation[language].p3}</p>
        <p>{translation[language].p4}</p>
        <img className={wrapper__divider} src={Leafes} />
        <div className={wrapper__content}>
          {data.map((el, i) => (
            <Question element={el} key={i} />
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default Contact

const translation = {
  pl: {
    title: "Masz pytania?",
    second_title: "Napisz do nas!",
    p: "Jeśli masz pytania, napisz do nas",
    p2: "lub skorzystaj z formularza kontaktowego.",
    p3: "Odpowiemy jak najszybciej",
    p4: "Sprawdź listę często zadawanych pytań:",
  },
  en: {
    title: "Do you have questions?",
    second_title: "Write to us!",
    p: "If you have any questions, please write to us",
    p2: "or use the contact form.",
    p3: "We will respond as soon as possible",
    p4: "Check out the list of frequently asked questions:",
  },
}

const data = [
  {
    question: "Co to jest CBD?",
    text:
      "CBD to kannabidiol, występuje w roślinach konopi i wykazuje działanie dobroczynne dla zdrowia człowieka. W przeciwieństwie do THC, CBD nie uzależnia, nie wykazuje również działania odurzającego. ",
  },
  {
    question: "Czym jest olej CBD?",
    text:
      "Olej CBD jest wytwarzany z konopi siewnych. Oleje CBD są skoncentrowaną formą ważnego dla zdrowia związku z konopi - CBD.",
  },
  {
    question: "Czy olejek CBD powoduje odurzenie?",
    text: "Nasze oleje nie mają działania odurzającego i uzależniającego.",
  },
  {
    question: "W jaki sposób działa CBD?",
    text:
      "CBD posiada wielokierunkowe zastosowanie i wspiera cały organizm człowieka. CBD działa na układ endokannabinoidowy naszego ciała.",
    listTitle: "Układ endokannabinoidowy ma szczególny wpływ między innymi na:",
    listElements: [
      "poziom odporności i reakcje immunologiczne organizmu,",
      "koncentrację, zdolność, zapamiętywania, uczenia się,",
      "odpowiednią pracę układu trawienia i apetyt,",
      "emocje i radzenie sobie ze stresem,",
      "równowagę na płaszczyźnie fizycznej i psychicznej,",
      "regulację temperatury ciała i ciśnienia krwi,",
      " prawidłową pracę układu nerwowego,",
      " ochronę komórek przed rakowaceniem.",
    ],
  },
  {
    question: "Jakie są korzyści wynikające ze stosowania olejków CBD?",
    text:
      "Wiele osób sięga po produkty z CBD, w celu poprawienia swojego zdrowia i samopoczucia. Preparaty z CBD mają bardzo szerokie zastosowanie. Wspierają dobry nastrój, ograniczają poziom stresu, pozwalają się wyspać i zregenerować po ciężkim dniu. Dodatkowo CBD posiada wiele zastosowań medycznych i wciąż jest badane w tym kierunku.",
  },
  {
    question: "Jakie są medyczne wskazania do stosowania CBD?",
    text:
      "CBD jest w stanie poprawić komfort życia i wesprzeć terapię wielu chorób. Obecnie liczne badania potwierdzają medyczne zastosowanie CBD.",
    listTitle:
      "Dzięki stosowaniu CBD mogą poprawić swój komfort życia osoby cierpiące między innymi na:",
    listElements: [
      "stany lękowe, nerwice",
      "epilepsję",
      "uzależnienia",
      "nowotwory",
      "autyzm",
      "spadek odporności",
      "choroby autoimmunologiczne",
    ],
  },
  {
    question: "Jaką dawkę i jak często przyjmować olejek CBD?",
    text:
      "Warto zacząć od mniejszej dawki i zwiększać ją z czasem do uzyskania pożądanych efektów terapeutycznych. Optymalne działanie preparatu uzyskuje się po ok 7-10 dniach. Większość osób stosuje dzienne dawki w przedziale 10-50mg CBD.",
  },
  {
    question: "Czy stężenie CBD ma znaczenie?",
    text:
      "Stężenie oleju CBD jest ważne, ale nie jest to jedyny czynnik.Istotne znaczenie mają pozostałe substancje z konopi jak kannabinoidy, terpeny oraz flawonoidy.  Tworzą one mieszaninę aktywnych składników wpływających pozytywnie na nasze zdrowie. ",
  },
  {
    question: "Co oznacza, że olejek jest FULL SPECTRUM?",
    text:
      "Full spectrum oznacza, że z oleju na etapie produkcji nie są usuwane cenne dla zdrowia kannabinoidy, terpeny i flawonoidy. Olej jest nieco bardziej “brudny” i oprócz czystego CBD posiada inne dobroczynne związki.",
  },
  {
    question: "W jaki sposób przechowywać olejki CBD?",
    text:
      "Olejki CBD przechowuj w ciemnym i  chłodnym miejscu. Tak przechowany olejek nie utraci swoich właściwości. Okres przydatności oleju to około rok od daty produkcji.",
  },
  {
    question: "Czy olejki CBD są bezpieczne?",
    text:
      "Organizm dobrze toleruje CBD i jest ono powszechnie uważane za bezpieczne. ",
  },
  {
    question: "W jaki sposób produkowany jest olejek CBD?",
    text:
      "Dla najlepszego efektu wybraliśmy do produkcji oleju ekstrakcję alkoholową. CBD pozyskujemy tylko z polskich konopi. Olejem nośnikowym jest zdrowy olej MCT.",
  },
  {
    question: "Czy olejki CBD The Old Hemp to polski produkt?",
    text:
      "Nasze oleje są przetwarzane z konopi pochodzących z lubelskich plantacji. Wspieramy polskich rolników.",
  },
]
